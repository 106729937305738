/**
 * @flow
 */

/* eslint-disable i18next/no-literal-string */

export function jargonize(message: string, jargon: Object): string {
  // walk through our string by our jargon marker retrieving the jargon keys
  const jargonTerms = [];
  for (const segment: string of message.split("#[JARGON:")) {
    const jargonEnd: number = segment.indexOf("]");
    if (jargonEnd >= 0) {
      jargonTerms.push(segment.slice(0, jargonEnd));
    }
  }
  // now we can swap out the keys that we found in our final message with values from our lookup table
  let finalMessage: string = message;
  for (const jargonKey: string of jargonTerms) {
    const jargonValue = jargon[jargonKey.replace(" ", "_")];
    if (jargonValue !== undefined) {
      finalMessage = finalMessage.replace(`#[JARGON:${jargonKey}]`, jargonValue);
    }
  }
  return finalMessage;
}
